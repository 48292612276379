/* ##remfixer: 1080 */
.CommentDetails[data-v-75b0f961] {
  position: relative;
  margin-top: -1.685185rem;
  min-height: 100%;
  background-color: #fff;
  overflow: hidden;
}
.replys[data-v-75b0f961] {
  border-top: 0.333333rem solid #f2f2f2;
  margin-bottom: 0.333333rem;
}
/* ##remfixer: 1080 */
.app_top {
  position: relative;
  padding: 0 0 0 1.388889rem;
  padding-right: 0.444444rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.app_top .backBtn {
  position: absolute;
  width: 1.222222rem;
  height: 100%;
  top: 0;
  left: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAYFBMVEUAAAADAABqAAADAAAFAAAGAAADAAADAAAEAAAEAAAFAAAHAAAfAAAEAAAEAAAFAAAEAAAIAAAUAAAvAAAEAAAFAAAFAAAFAAAGAAAKAAAPAAAEAAADAAANAAAEAAAGAABj0Nu9AAAAIHRSTlMAmQKSXFRNlng9LyQIhX9lRB4MBXBqYzYpGBCJSBSNV/IWLyYAAAD7SURBVFjD7ddZDoJQFAPQ+1CQQRmUedz/Lk3oixtoP0h8XcAJH9BeLCQkxKyIc4mTOecSkePcRDu1O9OwzgqnUjkR6SRwetaJ4dxZ5/COkbnDOUgm8k7MOj2chHUqkdPBWUnn452adVo4Gek8VU4KZ9A4D9Z5eydnnVLq3EbSmV9apyCdTeRYKtoLu53OEpldpKF/3VrJnqn7mGl2rH2qlj7lpQxS+aal4QFppqUc0mujpdG/mRMtFZD2hpaaXfT52rRoigmVgsplM5eaMcGc+JkUDWVtJjoBEl7qNEcbzjZfmqJDspeVZmWq0mxMVJrThX788CsaEvJP+QLE8Alc/Rg3gwAAAABJRU5ErkJggg==) no-repeat center center / auto 0.666667rem;
  z-index: 2;
}
.app_top .AppBlockNormal .img {
  width: 1.25rem;
  height: 1.25rem;
}
.app_top .AppBlockNormal .app-title {
  margin-bottom: 0.166667rem;
}
.replys .comment {
  border: none;
}
.block_no {
  width: 100%;
  padding-top: 0.740741rem;
}
.block_no i {
  display: block;
  width: 4.444444rem;
  height: 4.444444rem;
  margin: 0 auto;
  background: url(../../public/img/no-reply.png) no-repeat;
  background-size: 100% 100%;
}
.block_no p {
  opacity: 0.3;
  font-size: 0.444444rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  text-align: center;
}
.floatDownload {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.138889rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 2;
}
.floatDownload .AppInstallBtn {
  display: block;
  width: 6.666667rem;
  height: 1.055556rem;
  margin: 0 auto;
}
.floatDownload .AppInstallBtn-btn {
  width: 13.333333rem;
  height: 2.111111rem;
  line-height: 2.111111rem;
  font-size: 0.888889rem;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/*# sourceMappingURL=CommentDetail-vue.3efdf6ee.css.map*/