/* ##remfixer: 1080 */

.CommentDetails {
  position: relative;
  margin-top: -1.685185rem;
  min-height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.replys {
  border-top: 0.333333rem solid #f2f2f2;
  margin-bottom: 0.333333rem;
}